<template>
  <Modal addClass="modal-group-vod modal-movie" :showLoader="showLoader">
    <ModalHeaderVod
      :asset="asset"
      @play="onPlay(asset)"
      @favorite="onFavorite(asset)"
      @score="onChangeScore(asset, $event)"
      :poster="poster()"
      :posterMini="miniPoster()"
    />

    <div class="modal-bottom fade" :class="{ show: showBottom }">
      <template v-if="listaFavoritos.length > 0">
        <h6>Tus series favoritas</h6>

        <div class="view-render">
          <Slider :data="listaFavoritos">
            <template slot-scope="itemslider">
              <template v-for="(item, index) of itemslider.data">
                <CardVOD
                  v-if="item.idAsset"
                  :key="item.idAsset"
                  :data="item"
                  :showGroupInfo="false"
                  :sliderId="'capitulo-favorito'"
                  :sliderTitle="'Tus series favoritas'"
                  :cardId="'card-' + index"
                />
                <CardVOD
                  v-else-if="item.idSerie"
                  :key="item.idSerie"
                  :data="item"
                  :showGroupInfo="true"
                  :sliderId="'serie-favorita'"
                  :sliderTitle="'Tus series favoritas'"
                  :cardId="'card-' + index"
                />
              </template>
            </template>
          </Slider>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "ModalGroupVod",

  inject: ["getPlayer"],

  components: {
    Modal: () => import("@/components/Modal.vue"),
    ModalHeaderVod: () => import("@/components/ModalHeaderVod.vue"),
    Slider: () => import("@/components/Slider.vue"),
    CardVOD: () => import("@/components/Card-VOD.vue"),
  },

  data() {
    return {
      asset: {
        userRating: 0,
        favorite: false,
      },
      listaFavoritos: [],
      showLoader: true,
      showBottom: false,
    };
  },

  mounted() {
    this.$bus.$on("modal-group-vod:show", this.show);
  },

  beforeDestroy() {
    this.$bus.$off("modal-group-vod:show", this.show);
  },

  methods: {
    poster() {
      return this.asset.poster;
    },

    miniPoster() {
      return telecentro.tplay.core.api.traerPosterHWSeries(
        this.asset.idSerie,
        1,
        1
      );
    },

    show(obj) {
      const self = this;

      tplay.resetVueData(self);

      //Empiezo a cargar el contenido inmediatamente, mientras en paralelo abro el modal
      telecentro.tplay.core.helpers.join(
        __.partial(showModal, ".modal-group-vod"),
        __.partial(self.loadDetail, obj.data)
      )(function () {
        self.showDetail();
      });
    },

    loadDetail(asset, onFinish) {
      const self = this;
      const group = asset.group ? asset.group : asset;
      const idAsset = asset.idAsset || group.chapters[0].idAsset;
      const idSerie = asset.idSerie;

      //Es necesario llenar el buffer de favoritos para que los datos del episodio vengan bien
      function getDatosEpisodio(done) {
        telecentro.tplay.core.favoritos.traerFavoritos(function (arrFavoritos) {
          telecentro.tplay.core.series.datosDeCapitulo(idAsset, (data) => {
            //Asigno con Object.assign para no perder la reactividad en las subpropiedades de asset.
            Object.assign(self.asset, data);
            done();
          });
        });
      }

      //Junto los otros episodios favoritos de la misma serie con otras series favoritas
      function getRecomendados(done) {
        telecentro.tplay.core.favoritos.traerFavoritosPorTipo(function (fav) {
          var seriesFavoritas = __.reject(
            fav.seriesAgrupadas,
            (item) => item.idSerie === idSerie
          );

          if (group.chapters && group.chapters.length > 1) {
            //Seteo el grupo de cada episodio, para poder volver desde la navegación
            //TODO: hacer de una forma menos chancha
            for (var i = 0; i < group.chapters.length; i++) {
              group.chapters[i].group = group;
            }

            var episodiosGrupoFiltrados = __.reject(
              group.chapters,
              (item) => item.idAsset == idAsset
            );

            self.listaFavoritos =
              episodiosGrupoFiltrados.concat(seriesFavoritas);
          } else {
            self.listaFavoritos = seriesFavoritas;
          }

          done();
        });
      }

      telecentro.tplay.core.helpers.join(
        getRecomendados,
        getDatosEpisodio
      )(onFinish);
    },

    showDetail() {
      const self = this;

      if (gtag) {
        gtag("event", "Detalle grupo VOD", {
          event_category: "ModalGroupVod",
          event_action: "Detalle grupo VOD",
          event_label: self.asset.title || self.asset.name,
          value: "0",
        });
      }

      self.asset.title = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.title
      );
      self.asset.synopsis = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.synopsis
      );

      self.showLoader = false;

      //Espero a que se aplique el showLoader
      setTimeout(function () {
        self.showBottom = true;
      }, 100);
    },

    onFavorite(asset) {
      const self = this;
      const favorite = !asset.favorite;

      const after = function () {
        asset.favorite = favorite;
      };

      if (favorite) {
        telecentro.tplay.core.favoritos.agregarFavoritos(asset, after);
      } else {
        telecentro.tplay.core.favoritos.quitarFavoritos(asset, after);
      }
    },

    onPlay(asset) {
      const player = this.getPlayer();

      if (player.isActive() && player.isAsset(asset.idAsset)) {
        player.onControlPlayPause();
      } else {
        if (gtag) {
          gtag("event", "Reproducir desde modal", {
            event_category: "ModalGroupVod",
            event_action: "Reproducir desde modal",
            event_label: this.asset.title,
            value: "0",
          });
        }

        const opt = {
          rail: {
            show: false,
          },
        };

        this.$bus.$emit("player:set-source", asset, opt);
      }
    },

    onChangeScore(capitulo, userRating) {
      const self = this;

      telecentro.tplay.core.vod.cambiarScoreAssetSeries(
        capitulo.idAsset,
        userRating,
        function (rating) {
          capitulo.rating = rating;
          capitulo.userRating = userRating;

          self.$forceUpdate();
        },
        function (err) {
          self.$bus.$emit(
            "show-toast",
            "error",
            "No se pudo obtener el rating del episodio."
          );
        }
      );
    },
  },
};
</script>
